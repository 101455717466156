import LayoutMain from "@/layouts/LayoutMain.vue";
import LayoutNaked from "@/layouts/LayoutNaked.vue";

const routes = [
  {
    path: "/",
    component: LayoutMain,
    children: [
      {
        path: "/",
        name: "DASHBOARD",
        component: () => import("@/pages/dashboard/Dashboard.vue"),
        meta: {
          title: "대시보드",
        },
      },
      // 우대사업자 확인
      {
        path: "/company/bizsize",
        name: "COMPANY_BIZSIZE",
        component: () => import("@/pages/company/CompanyBizsize"),
        meta: {
          title: "우대사업자 조회"
        }
      },
      // 하위대리점 관리
      {
        path: "/agent/item",
        name: "AGENT_ITEM",
        component: () => import("@/pages/agent/AgentItem.vue"),
        meta: {
          title: "하위대리점 등록",
        },
      },
      {
        path: "/agent/list",
        name: "AGENT_LIST",
        component: () => import("@/pages/agent/AgentList.vue"),
        meta: {
          title: "하위대리점 목록",
        },
      },
      {
        path: "/agent/details/:agentSeq",
        name: "AGENT_DETAIL",
        component: () => import("@/pages/agent/AgentDetail.vue"),
        meta: {
          title: "영업 대리점 상세",
        },
      },

      // 가맹점관리
      {
        path: "/merchant/applyitem",
        name: "MERCHANT_APPLY_ITEM",
        component: () => import("@/pages/merchant/ApplyItem.vue"),
        meta: {
          title: "가맹점 신청",
        },
      },
      {
        path: "/merchant/applylist",
        name: "MERCHANT_APPLY_LIST",
        component: () => import("@/pages/merchant/ApplyList.vue"),
        meta: {
          title: "가맹점 신청 목록",
        },
      },
      {
        path: "/merchant/applydetail/:index",
        name: "MERCHANT_APPLY_DETAIL",
        component: () => import("@/pages/merchant/ApplyDetail.vue"),
        meta: {
          title: "가맹점 신청 목록",
        },
      },
      {
        path: "/merchant/list",
        name: "MERCHANT_LIST",
        component: () => import("@/pages/merchant/MerchantList.vue"),
        meta: {
          title: "가맹점 목록",
        },
      },
      {
        path: "/merchant/detail/:pgMerchNo",
        name: "MERCHANT_DETAIL",
        component: () => import("@/pages/merchant/MerchantDetail.vue"),
        meta: {
          title: "가맹점 상세 정보",
        },
      },
      {
        path: "/merchant/terminallist",
        name: "MERCHANT_TERMINAL_LIST",
        component: () => import("@/pages/merchant/TerminalList.vue"),
        meta: {
          title: "단말기 관리",
        },
      },

      // 매출 조회
      {
        path: "/statistics/merchants",
        name: "STATISTICS_MERCHANTS",
        component: () => import("@/pages/statistics/StatisticsMerchants"),
        meta: {
          title: "가맹점별 매출 조회"
        }
      },
      {
        path: "/statistics/merchant/details",
        name: "STATISTICS_MERCHANT_DETAILS",
        component: () => import("@/pages/statistics/StatisticsMerchantDetails"),
        meta: {
          title: "가맹점 매출 상세",
        },
      },
      // 정산관리
      {
        path: "/settlement/agentlist",
        name: "SETTLEMENT_AGENT_LIST",
        component: () => import("@/pages/settlement/SettlementAgent.vue"),
        meta: {
          title: "하위대리점 정산내역",
        },
      },
      {
        path: "/settlement/merchantlist",
        name: "SETTLEMENT_MERCHANT_LIST",
        component: () => import("@/pages/settlement/SettlementMerchant.vue"),
        meta: {
          title: "가맹점 정산내역",
        },
      },
      {
        path: "/settlement/agentschelist",
        name: "SETTLEMENT_AGENCT_SCHE_LIST",
        component: () => import("@/pages/settlement/SettlementScheAgent.vue"),
        meta: {
          title: "대리점 정산예정",
        },
      },
      {
        path: "/settlement/agentscheservicelist",
        name: "SETTLEMENT_AGENCT_SCHE_SERVICE_LIST",
        component: () => import("@/pages/settlement/SettlementScheAgentService.vue"),
        meta: {
          title: "하위 대리점 지급예정 조회",
        },
      },
      {
        path: "settlement/agentdpstscheservicelist",
        name: "SETTLEMENT_AGENCT_DPST_SCHE_SERVICE_LIST",
        component: () => import("@/pages/settlement/SettlementDpstScheAgentService.vue"),
        meta: {
          title: "하위 대리점 입금예정 조회",
        },
      },
      // 결제관리
      {
        path: "/history/cardhistory",
        name: "CARD_HISTORY",
        component: () => import("@/pages/history/CardHistory.vue"),
        meta: {
          title: "신용카드 결제내역",
        },
      },
      {
        path: "/history/bankhistory",
        name: "BANK_HISTORY",
        component: () => import("@/pages/history/BankHistory.vue"),
        meta: {
          title: "계좌이체 결제내역",
        },
      },
      {
        path: "/history/cashhistory",
        name: "CASH_HISTORY",
        component: () => import("@/pages/history/CashHistory.vue"),
        meta: {
          title: "현금영수증 발급내역",
        },
      },

      // 정보
      {
        path: "/information/mypage",
        name: "SMS_PAYMENTS_HISTORY",
        component: () => import("@/pages/information/MyPage.vue"),
        meta: {
          title: "마이페이지",
        },
      },
      {
        path: "/information/noticelist",
        name: "NOTICE_LIST",
        component: () => import("@/pages/information/NoticeList.vue"),
        meta: {
          title: "공지사항",
        },
      },

      // 약관
      {
        path: "/terms/use",
        name: "TERMS_USE",
        component: () => import("@/pages/terms/TermsUse.vue"),
        meta: {
          title: "이용약관",
        },
      },
      {
        path: "/terms/privacy",
        name: "TERMS_PRIVACY",
        component: () => import("@/pages/terms/TermsPrivacy.vue"),
        meta: {
          title: "개인정보 처리방침",
        },
      },
      {
        path: "/terms/payments",
        name: "TERMS_PAYMENTS",
        component: () => import("@/pages/terms/TermsPayments.vue"),
        meta: {
          title: "전자금융거래 기본약관",
        },
      },
    ],
  },
  {
    path: "/",
    component: LayoutNaked,
    children: [
      {
        path: "/login",
        name: "LOGIN",
        component: () => import("@/pages/auth/Login.vue"),
      },
      {
        path: "/findid",
        name: "FIND_ID",
        component: () => import("@/pages/auth/FindId.vue"),
      },
      {
        path: "/findpw",
        name: "FIND_PW",
        component: () => import("@/pages/auth/FindPw.vue"),
      },
      {
        path: "/404",
        name: "NOT_FOUND",
        component: () => import("@/components/error/NotFound.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
      },
      {
        path: "/statistics/merchant/details/:pgMerchNo",
        name: "STATISTICS_MERCHANT_DETAILS_",
        component: () => import("@/pages/statistics/StatisticsMerchantDetails"),
        props: route => ({
          propPgMerchNo: route.params.pgMerchNo,
          propPeriodType: route.query.periodType,
          propInquiryStartDate: route.query.inquiryStartDate,
          propInquiryEndDate: route.query.inquiryEndDate,
        }),
        meta: {
          title: "가맹점 매출 상세",
          leftMenu: 'disable'
        },
        alias: [{ path: "/statistics/merchant/details" }]
      },
    ],
  },
];

export default routes;
